//
//
//
//
//
//
//
//
//
//
//
//
//
//

import productMixin from '~/mixin/product'

export default {
  mixins: [productMixin],
}
