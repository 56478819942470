import { cacheAdapterEnhancer } from 'axios-extensions'
import LRUCache from 'lru-cache'
import { filter } from 'lodash'

const HEADER_NONCE_KEY = 'X-WC-Store-API-Nonce'

export default ({ $axios, $config, store }, inject) => {
  const { perPage } = $config

  const ONE_HOUR = 1000 * 60 * 60
  const defaultCache = new LRUCache({ maxAge: ONE_HOUR, max: 100 })

  const axios = $axios.create({
    baseUrl: 'https://wc.drawnjewellery.com/',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      [HEADER_NONCE_KEY]: store.state.nonce.token || '',
    },
    params: {
      _locale: 'user',
    },
    adapter: cacheAdapterEnhancer($axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache',
      defaultCache,
    }),
  })

  const updateNonce = (headers) => {
    const { [HEADER_NONCE_KEY.toLowerCase()]: nonce } = headers

    store.commit('nonce/UPDATE_NONCE', nonce)
  }

  const updateNonceAndCart = (data, headers) => {
    updateNonce(headers)

    // Add response to store
    store.commit('cart/UPDATE_CART', data)

    return data
  }

  const getShopSettings = () => {
    return axios
      .get('/api/wp/v2/shop-setting')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getFaq = () => {
    return axios
      .get('/api/wp/v2/faq')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getArchive = () => {
    return axios
      .get('/api/wp/v2/archive')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getShippingAndReturns = () => {
    return axios
      .get('/api/wp/v2/shipping-and-returns')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getSizeGuide = () => {
    return axios
      .get('/api/wp/v2/sizing-guide')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getContacts = () => {
    return axios
      .get('/api/wp/v2/contacts')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getInformation = () => {
    return axios
      .get('/api/wp/v2/information')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getStockist = () => {
    return axios
      .get('/api/wp/v2/stockist')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getDealersNotice = () => {
    return axios
      .get('/api/wp/v2/notice')
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getSidebar = () => {
    return axios
      .get('/api/wp/v2/sidebar', { useCache: true })
      .then(({ data }) => data)
      .catch((e) => e)
  }

  const getProducts = (page = 1, per_page = perPage, params = {}) => {
    return axios
      .get('/api/wc/store/products', {
        params: {
          page,
          per_page,
          order: 'desc',
          orderby: 'date',
          ...params,
        },
      })
      .then(({ data: products, headers }) => {
        const { 'x-wp-total': total, 'x-wp-totalpages': totalPages } = headers

        return {
          products,
          total: parseInt(total),
          totalPages: parseInt(totalPages),
        }
      })
      .catch((e) => e)
  }

  const getProductBySlug = (slug) => {
    return (
      axios
        // find product by slug from wp
        .get('/api/wp/v2/product', {
          params: {
            slug,
            per_page: 1,
          },
        })
        .then(({ data, headers }) => {
          if (data.length) {
            // find product by ID from store
            const { id } = data.shift()

            return axios
              .get(`/api/wc/store/products/${id}`)
              .then(({ data }) => data)
          }

          return null
        })
    )
  }

  const getProductById = (id) => {
    return axios.get(`/api/wc/store/products/${id}`).then(({ data }) => data)
  }

  const getCollectionBySlug = (slug) => {
    return axios
      .get('/api/wp/v2/collections', {
        params: {
          slug,
        },
      })
      .then(({ data }) => data[0])
      .catch((e) => e)
  }

  const getCollaborationBySlug = (slug) => {
    return axios
      .get('/api/wp/v2/projects', {
        params: {
          slug,
        },
      })
      .then(({ data }) => data[0])
      .catch((e) => e)
  }

  const getPressBySlug = (slug) => {
    return axios
      .get('/api/wp/v2/press', {
        params: {
          slug,
        },
      })
      .then(({ data }) => data[0])
      .catch((e) => e)
  }

  const getCategories = () => {
    return axios
      .get('/api/wc/store/products/categories', { useCache: true })
      .then(({ data }) => {
        return filter(data, (item) => item.slug !== 'uncategorized')
      })
      .catch((e) => e)
  }

  const getStoreCategoriesMenu = () => {
    return axios
      .get('/api/menus/v1/menus/store-categories')
      .then(({ data }) => data.items || [])
      .catch(() => [])
  }

  const addCartItem = (id, quantity, variation) => {
    return axios
      .post('/api/wc/store/cart/add-item', {
        id,
        quantity,
        variation,
      })
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
  }

  const removeCartItem = (key) => {
    return axios
      .post('/api/wc/store/cart/remove-item', {
        key,
      })
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
  }

  const clearCart = () => {
    return axios
      .delete('/api/wc/store/cart/items')
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
      .catch(() => {
        // empty
      })
  }

  const getCart = () => {
    return axios.get('/api/wc/store/cart').then(({ data, headers }) => {
      updateNonce(headers)

      return data
    })
  }

  const updateCustomer = (payload) => {
    return axios
      .post('/api/wc/store/cart/update-customer', {
        ...payload,
      })
      .then(({ data, headers }) => {
        updateNonce(headers)

        return data
      })
  }

  const selectShippingRate = (packageId, rateId) => {
    return axios
      .post('/api/wc/store/cart/select-shipping-rate', {
        package_id: packageId,
        rate_id: rateId,
      })
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
  }

  const checkout = (payload) => {
    return axios
      .post('/api/wc/store/checkout', {
        ...payload,
      })
      .then(({ data, headers }) => {
        updateNonce(headers)

        return data
      })
  }

  const orderSummary = (id, key) => {
    return axios
      .get(`/api/wc/store/order/${id}`, {
        params: {
          key,
        },
      })
      .then(({ data }) => data)
      .catch(() => {
        // empty
      })
  }

  const applyCouponCode = (code) => {
    return axios
      .post('/api/wc/store/cart/apply-coupon', {
        code,
      })
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
  }

  const removeCouponCode = (code) => {
    return axios
      .post('/api/wc/store/cart/remove-coupon', {
        code,
      })
      .then(({ data, headers }) => updateNonceAndCart(data, headers))
  }

  inject('api', {
    archive() {
      return getArchive()
    },
    shopSettings() {
      return getShopSettings()
    },
    faq() {
      return getFaq()
    },
    shippingAndReturns() {
      return getShippingAndReturns()
    },
    sizeGuide() {
      return getSizeGuide()
    },
    contacts() {
      return getContacts()
    },
    information() {
      return getInformation()
    },
    sidebar() {
      return getSidebar()
    },
    stockist() {
      return getStockist()
    },
    dealersNotice() {
      return getDealersNotice()
    },
    collections(slug) {
      return getCollectionBySlug(slug)
    },
    collaborations(slug) {
      return getCollaborationBySlug(slug)
    },
    press(slug) {
      return getPressBySlug(slug)
    },
    products(page = 1, per_page = perPage, params = {}) {
      return getProducts(page, per_page, params)
    },
    product(slug) {
      return getProductBySlug(slug)
    },
    productId(id) {
      return getProductById(id)
    },
    categories() {
      return getCategories()
    },
    addCartItem(id, quantity, variation) {
      return addCartItem(id, quantity, variation)
    },
    cart() {
      return getCart()
    },
    shippingRate(packageId, rateId) {
      return selectShippingRate(packageId, rateId)
    },
    storeCategoryMenu() {
      return getStoreCategoriesMenu()
    },
    updateCustomer,
    removeCartItem,
    clearCart,
    checkout,
    orderSummary,
    applyCouponCode,
    removeCouponCode,
  })
}
