//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  layout: 'fullPage',
  head() {
    return {
      htmlAttrs: {
        class: ['static-page'],
      },
      bodyAttrs: {
        class: ['static-page'],
      },
    }
  },
}
