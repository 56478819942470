//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

extend('email', email)

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      subscribeEmail: '',
    }
  },
  methods: {
    async subscribe() {
      await this.$axios
        .post('/subscribe', {
          email: this.subscribeEmail,
        })
        .then(({ status }) => {
          if (status >= 200) {
            this.subscribeEmail = ''

            this.$notification.show({
              message: 'Successfully subscribed!',
            })
          }
        })
        .catch(
          ({
            response: {
              data: {
                body: { errors },
              },
            },
          }) => {
            errors.forEach(({ message }) => {
              this.$notification.error({
                message,
              })
            })
          }
        )
    },
  },
}
