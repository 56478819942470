export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('settings/getSettings')
    await dispatch('category/getCategories')
    await dispatch('site/getSidebar')
    await dispatch('cart/getCart')
  },
  clearAll({ dispatch }) {
    dispatch('stripe/clearAll')
    dispatch('cart/clearCart')
  },
}
