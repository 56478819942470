//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('settings', {
      hasAnnouncement({ settings }) {
        return settings.enable_site_announcement
      },
      message({ settings }) {
        return settings.site_announcement
      },
    }),
  },
}
