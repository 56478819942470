//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      cartList: false,
      innerWidth: null,
    }
  },
  computed: {
    ...mapState('site', {
      sidebarVisible: 'active',
    }),
    cartCount() {
      return this.$store.getters['cart/itemsCount']
    },
    isEnabled() {
      return this.innerWidth > 760
    },
  },
  mounted() {
    this.getInnerWidth()

    window.addEventListener('resize', () => {
      this.getInnerWidth()
    })
  },
  methods: {
    ...mapMutations('site', ['SET_ACTIVE']),
    setSidebar() {
      this.SET_ACTIVE(!this.sidebarVisible)
    },
    getInnerWidth() {
      this.innerWidth = window.innerWidth
    },
  },
}
