export const usePriceFormat = () => {
  const priceFormat = (price, minorUnit) => {
    const divdend = 10 ** minorUnit
    const number = parseFloat(price) / divdend

    return number.toFixed(minorUnit)
  }

  return {
    priceFormat,
  }
}
