import { get } from 'lodash'
import { usePriceFormat } from '~/composables/usePriceFormat'

const { priceFormat } = usePriceFormat()

export const state = () => ({
  cart: {},
})

export const getters = {
  itemsCount(state) {
    return get(state.cart, 'items_count', 0)
  },
  items(state) {
    return get(state.cart, 'items', [])
  },
  totals(state) {
    return get(state.cart, 'totals', {})
  },
  currencyMinorUnit(state, getters) {
    const { currency_minor_unit: currencyMinorUnit = 2 } = getters.totals

    return currencyMinorUnit
  },
  currencyCode(state, getters) {
    const { currency_code: currencyCode } = getters.totals

    return currencyCode
  },
  currencySymbol(state, getters) {
    const { currency_symbol: currencySymbol } = getters.totals

    return currencySymbol
  },
  hasCalculatedShipping(state) {
    return get(state.cart, 'has_calculated_shipping', false)
  },
  shippingRates(state) {
    return get(state.cart, 'shipping_rates', [])
  },
  totalItems(state, getters) {
    const { total_items: totalItems = 0 } = getters.totals

    return totalItems
  },
  totalDiscount(state, getters) {
    const { total_discount: totalDiscount = 0 } = getters.totals

    return totalDiscount
  },
  totalItemsFormatted(state, getters) {
    return `${getters.currencySymbol}${priceFormat(
      getters.totalItems,
      getters.currencyMinorUnit
    )}`
  },
  totalDiscountFormatted(state, getters) {
    return `${getters.currencySymbol}${priceFormat(
      getters.totalDiscount,
      getters.currencyMinorUnit
    )}`
  },
  totalPrice(state, getters) {
    const { total_price: totalPrice = 0 } = getters.totals

    return totalPrice
  },
  totalPriceFormatted(state, getters) {
    return `${getters.currencySymbol}${priceFormat(
      getters.totalPrice,
      getters.currencyMinorUnit
    )}`
  },
  shippingPrice(state, getters) {
    const { total_shipping: shippingPrice = 0 } = getters.totals

    return shippingPrice
  },
  shippingPriceFormatted(state, getters) {
    return `${getters.currencySymbol}${priceFormat(
      getters.shippingPrice,
      getters.currencyMinorUnit
    )}`
  },
  shippingAddress(state) {
    return get(state.cart, 'shipping_address', {})
  },
  billingAddress(state) {
    return get(state.cart, 'billing_address', {})
  },
  coupons(state) {
    return get(state.cart, 'coupons', [])
  },
}

export const mutations = {
  UPDATE_CART(state, payload) {
    state.cart = payload
  },
}

export const actions = {
  async getCart({ commit }) {
    await this.$api.cart().then((data) => {
      commit('UPDATE_CART', data)
    })
  },
  async removeCartItem({ dispatch }, key) {
    await this.$api.removeCartItem(key).then(() => {
      dispatch('getCart')
    })
  },
  async clearCart() {
    await this.$api.clearCart()
  },
}
