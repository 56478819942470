import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import { forEach } from 'lodash'

forEach(rules, (_, rule) => {
  extend(rule, {
    // eslint-disable-next-line import/namespace
    ...rules[rule],
    message: messages[rule],
  })
})

setInteractionMode('eager')

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
