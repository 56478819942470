//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import productMixin from '~/mixin/product'

export default {
  mixins: [productMixin],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reconfirm: false,
    }
  },
  computed: {
    variations() {
      return this.product.variation || []
    },
    qty() {
      return this.product.quantity || 0
    },
    lineTotal() {
      return this.product.totals.line_total || 0
    },
    itemKey() {
      return this.product.key
    },
  },
  methods: {
    ...mapActions('cart', ['removeCartItem']),
    removeItem(key) {
      if (this.reconfirm) {
        this.removeCartItem(key)
      } else {
        this.reconfirm = true

        setTimeout(() => {
          this.reconfirm = false
        }, 5000)
      }
    },
  },
}
