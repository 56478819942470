export const state = () => ({
  sidebar: [],
  active: false,
})

export const mutations = {
  SET_SIDEBAR(state, payload) {
    state.sidebar = payload
  },
  SET_ACTIVE(state, active) {
    state.active = active
  },
}

export const actions = {
  async getSidebar({ commit }) {
    await this.$api.sidebar().then((data) => {
      commit('SET_SIDEBAR', data)
    })
  },
}
