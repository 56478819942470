import { render, staticRenderFns } from "./fullPage.vue?vue&type=template&id=54776216&scoped=true&"
import script from "./fullPage.vue?vue&type=script&lang=js&"
export * from "./fullPage.vue?vue&type=script&lang=js&"
import style0 from "./fullPage.vue?vue&type=style&index=0&id=54776216&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54776216",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SiteHeader: require('/nuxtjs-drawn-jewellery/source/components/SiteHeader.vue').default,SiteFooter: require('/nuxtjs-drawn-jewellery/source/components/SiteFooter.vue').default,SiteFooterLogo: require('/nuxtjs-drawn-jewellery/source/components/SiteFooterLogo.vue').default})
