export const state = () => ({
  projects: [],
})

export const getters = {
  getProjectBySlug: (state) => (slug) => {
    return state.projects.find((cat) => cat.slug === slug)
  },
}

export const mutations = {
  UPDATE_PROJECTS(state, payload) {
    state.projects = payload
  },
}

export const actions = {
  async getProjects({ commit }) {
    await this.$api.projects().then((data) => {
      commit('UPDATE_PROJECTS', data)
    })
  },
}
