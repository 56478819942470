import { get } from 'lodash'
import { usePriceFormat } from '~/composables/usePriceFormat'

const { priceFormat } = usePriceFormat()

export default {
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      currentPrice: 0,
      images: [],
    }
  },
  created() {
    this.images = get(this.product, 'images', [])
  },
  computed: {
    name() {
      return this.product.name
    },
    productImage() {
      return get(this.product, 'images.0.src', '')
    },
    productImageSecondary() {
      return get(this.product, 'images.1.src', '')
    },
    onSale() {
      return this.product.on_sale
    },
    prices() {
      return get(this.product, 'prices', {})
    },
    regularPrice() {
      const { regular_price: regularPrice = '' } = this.prices

      return this.toPriceFormat(regularPrice)
    },
    salePrice() {
      const { sale_price: salePrice = '' } = this.prices

      return this.toPriceFormat(salePrice)
    },
    price() {
      const { price = '' } = this.prices

      return this.toPriceFormat(price)
    },
    currencySymbol() {
      const { currency_symbol: currencySymbol } = this.prices

      return currencySymbol
    },
    currencyMinorUnit() {
      const { currency_minor_unit: currencyMinorUnit } = this.prices

      return currencyMinorUnit
    },
    fullPrice() {
      let price = `${this.currencySymbol}${this.price}`

      price =
        this.onSale && this.salePrice
          ? `${price} <del>${this.currencySymbol}${this.regularPrice}</del>`
          : price

      return price
    },
    parentProduct() {
      return this.product.parent_product || null
    },
    slug() {
      const permalink = get(this.product, 'permalink', '')

      const url = new URL(permalink)

      return url.pathname.split('/').filter(Boolean).pop()
    },
    link() {
      return `/store/product/${this.slug}`
    },
    description() {
      return this.product.description
    },
    shortDescription() {
      return this.product.short_description
    },
    sku() {
      return this.product.sku
    },
    categories() {
      return this.product.categories
    },
    stock() {
      return this.product.stock_quantity || 99
    },
    inStock() {
      return this.product.is_in_stock
    },
    soldIndividually() {
      return this.product.sold_individually || false
    },
  },
  methods: {
    toPriceFormat(price) {
      return priceFormat(price, this.currencyMinorUnit)
    },
    galleryImages(limit = -1) {
      return limit !== -1 ? this.images.slice(0, limit) : this.images
    },
  },
}
