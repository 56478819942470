import { cloneDeep } from 'lodash'

export const state = () => ({
  source: null,
  element: null,
  invalid: true,
  error: '',
})

export const getters = {
  getSource({ source }) {
    return source
  },
}

export const mutations = {
  UPDATE_SOURCE(state, payload) {
    state.source = payload
  },
  UPDATE_ELEMENT(state, element) {
    state.element = element
  },
  SET_INVALID(state, invalid) {
    state.invalid = invalid
  },
  SET_ERROR_MESSAGE(state, error) {
    state.error = error
  },
}

export const actions = {
  updateSource({ commit }, payload) {
    commit('UPDATE_SOURCE', payload)
  },
  updateElement({ commit }, element) {
    const deep = cloneDeep(element)
    commit('UPDATE_ELEMENT', deep)
  },
  clearAll({ commit }) {
    commit('UPDATE_SOURCE', null)
    commit('UPDATE_ELEMENT', null)
  },
  setInvalid({ commit }, flag) {
    commit('SET_INVALID', flag)
  },
  setErrorMessage({ commit }, error) {
    commit('SET_ERROR_MESSAGE', error)
  },
  clearErrorMessage({ dispatch }) {
    dispatch('setErrorMessage', '')
  },
}
