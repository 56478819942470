import { render, staticRenderFns } from "./default.vue?vue&type=template&id=45ed3675&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SiteAnnouncement: require('/nuxtjs-drawn-jewellery/source/components/SiteAnnouncement.vue').default,SiteHeader: require('/nuxtjs-drawn-jewellery/source/components/SiteHeader.vue').default,SiteSidebar: require('/nuxtjs-drawn-jewellery/source/components/SiteSidebar.vue').default,SiteFooter: require('/nuxtjs-drawn-jewellery/source/components/SiteFooter.vue').default,SiteFooterLogo: require('/nuxtjs-drawn-jewellery/source/components/SiteFooterLogo.vue').default})
