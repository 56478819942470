export const state = () => ({
  collections: [],
})

export const getters = {
  getCollectionBySlug: (state) => (slug) => {
    return state.collections.find((cat) => cat.slug === slug)
  },
}

export const mutations = {
  UPDATE_COLLECTIONS(state, payload) {
    state.collections = payload
  },
}

export const actions = {
  async getCollections({ commit }) {
    await this.$api.collections().then((data) => {
      commit('UPDATE_COLLECTIONS', data)
    })
  },
}
