//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('site', {
      sidebarVisible: 'active',
    }),
    showAnnouncement() {
      return this.$route.path === '/'
    },
  },
}
