export default ({ $toast }, inject) => {
  const defaultAction = [
    {
      text: 'Close',
      onClick(e, toastObject) {
        toastObject.goAway(0)
      },
    },
  ]

  const show = ({ message, icon = '', action = defaultAction }) => {
    $toast.show(message, {
      icon,
      duration: 5000,
      action,
    })
  }

  const error = ({ message, icon = '', action = defaultAction }) => {
    $toast.error(message, {
      icon,
      action,
    })
  }

  inject('notification', {
    error,
    show,
  })
}
