//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { first } from 'lodash'
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('category', ['categories']),
    ...mapState('site', ['sidebar']),
  },
  mounted() {
    this.$el.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', () => {
        this.SET_ACTIVE(false)
      })
    })
  },
  methods: {
    ...mapMutations('site', ['SET_ACTIVE']),
    first(collection, property) {
      const head = first(collection)

      return head[property] || ''
    },
  },
}
