import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _129275f2 = () => interopDefault(import('../pages/archive.vue' /* webpackChunkName: "pages/archive" */))
const _53984b60 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2b8a450b = () => interopDefault(import('../pages/dealers-notice.vue' /* webpackChunkName: "pages/dealers-notice" */))
const _2d213b74 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _4308395c = () => interopDefault(import('../pages/information.vue' /* webpackChunkName: "pages/information" */))
const _132cd21a = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _19b0eea4 = () => interopDefault(import('../pages/shipping-and-returns.vue' /* webpackChunkName: "pages/shipping-and-returns" */))
const _5149aa30 = () => interopDefault(import('../pages/size-guide.vue' /* webpackChunkName: "pages/size-guide" */))
const _00ed0422 = () => interopDefault(import('../pages/stockists.vue' /* webpackChunkName: "pages/stockists" */))
const _2a1efb58 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _1ea2cee4 = () => interopDefault(import('../pages/store/cart.vue' /* webpackChunkName: "pages/store/cart" */))
const _54ddfc91 = () => interopDefault(import('../pages/store/cart/index.vue' /* webpackChunkName: "pages/store/cart/index" */))
const _5b388d57 = () => interopDefault(import('../pages/store/cart/checkout.vue' /* webpackChunkName: "pages/store/cart/checkout" */))
const _469c44a7 = () => interopDefault(import('../pages/store/category/_slug.vue' /* webpackChunkName: "pages/store/category/_slug" */))
const _3cb5c9ec = () => interopDefault(import('../pages/store/product/_slug.vue' /* webpackChunkName: "pages/store/product/_slug" */))
const _2f99a497 = () => interopDefault(import('../pages/thank-you/_id.vue' /* webpackChunkName: "pages/thank-you/_id" */))
const _06fb87fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c79cc0f0 = () => interopDefault(import('../pages/_category/_slug.vue' /* webpackChunkName: "pages/_category/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/archive",
    component: _129275f2,
    name: "archive"
  }, {
    path: "/contact",
    component: _53984b60,
    name: "contact"
  }, {
    path: "/dealers-notice",
    component: _2b8a450b,
    name: "dealers-notice"
  }, {
    path: "/faq",
    component: _2d213b74,
    name: "faq"
  }, {
    path: "/information",
    component: _4308395c,
    name: "information"
  }, {
    path: "/press",
    component: _132cd21a,
    name: "press"
  }, {
    path: "/shipping-and-returns",
    component: _19b0eea4,
    name: "shipping-and-returns"
  }, {
    path: "/size-guide",
    component: _5149aa30,
    name: "size-guide"
  }, {
    path: "/stockists",
    component: _00ed0422,
    name: "stockists"
  }, {
    path: "/store",
    component: _2a1efb58,
    name: "store"
  }, {
    path: "/store/cart",
    component: _1ea2cee4,
    children: [{
      path: "",
      component: _54ddfc91,
      name: "store-cart"
    }, {
      path: "checkout",
      component: _5b388d57,
      name: "store-cart-checkout"
    }]
  }, {
    path: "/store/category/:slug?",
    component: _469c44a7,
    name: "store-category-slug"
  }, {
    path: "/store/product/:slug?",
    component: _3cb5c9ec,
    name: "store-product-slug"
  }, {
    path: "/thank-you/:id?",
    component: _2f99a497,
    name: "thank-you-id"
  }, {
    path: "/",
    component: _06fb87fc,
    name: "index"
  }, {
    path: "/:category/:slug?",
    component: _c79cc0f0,
    name: "category-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
