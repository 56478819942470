export const state = () => ({
  categories: [],
})

export const getters = {
  getCategoryBySlug: (state) => (slug) => {
    return state.categories.find((cat) => cat.slug === slug)
  },
}

export const mutations = {
  UPDATE_CATEGORIES(state, payload) {
    state.categories = payload
  },
}

export const actions = {
  async getCategories({ commit }) {
    await this.$api.storeCategoryMenu().then((data) => {
      commit('UPDATE_CATEGORIES', data)
    })
  },
}
