export const state = () => ({
  settings: [],
})

export const mutations = {
  UPDATE_SETTINGS(state, payload) {
    state.settings = payload
  },
}

export const actions = {
  async getSettings({ commit }) {
    await this.$api.shopSettings().then((data) => {
      commit('UPDATE_SETTINGS', data)
    })
  },
}
