export const NuxtPicture = () => import('../../components/NuxtPicture.vue' /* webpackChunkName: "components/nuxt-picture" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const SiteAnnouncement = () => import('../../components/SiteAnnouncement.vue' /* webpackChunkName: "components/site-announcement" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const SiteFooterLogo = () => import('../../components/SiteFooterLogo.vue' /* webpackChunkName: "components/site-footer-logo" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const SiteSearchBar = () => import('../../components/SiteSearchBar.vue' /* webpackChunkName: "components/site-search-bar" */).then(c => wrapFunctional(c.default || c))
export const SiteSidebar = () => import('../../components/SiteSidebar.vue' /* webpackChunkName: "components/site-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/AddToCart/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddToCartSimple = () => import('../../components/AddToCart/AddToCartSimple.vue' /* webpackChunkName: "components/add-to-cart-simple" */).then(c => wrapFunctional(c.default || c))
export const AddToCartVariable = () => import('../../components/AddToCart/AddToCartVariable.vue' /* webpackChunkName: "components/add-to-cart-variable" */).then(c => wrapFunctional(c.default || c))
export const ArchiveProductFeed = () => import('../../components/Archive/ProductFeed.vue' /* webpackChunkName: "components/archive-product-feed" */).then(c => wrapFunctional(c.default || c))
export const CartAddressForm = () => import('../../components/Cart/AddressForm.vue' /* webpackChunkName: "components/cart-address-form" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutForm = () => import('../../components/Cart/CheckoutForm.vue' /* webpackChunkName: "components/cart-checkout-form" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutSelect = () => import('../../components/Cart/CheckoutSelect.vue' /* webpackChunkName: "components/cart-checkout-select" */).then(c => wrapFunctional(c.default || c))
export const CartCouponInput = () => import('../../components/Cart/CouponInput.vue' /* webpackChunkName: "components/cart-coupon-input" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/Cart/Item.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartList = () => import('../../components/Cart/List.vue' /* webpackChunkName: "components/cart-list" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentSelector = () => import('../../components/Cart/PaymentSelector.vue' /* webpackChunkName: "components/cart-payment-selector" */).then(c => wrapFunctional(c.default || c))
export const CartShippingSelector = () => import('../../components/Cart/ShippingSelector.vue' /* webpackChunkName: "components/cart-shipping-selector" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/Cart/Summary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const FormBaseProvider = () => import('../../components/Form/BaseProvider.vue' /* webpackChunkName: "components/form-base-provider" */).then(c => wrapFunctional(c.default || c))
export const FormTextInput = () => import('../../components/Form/TextInput.vue' /* webpackChunkName: "components/form-text-input" */).then(c => wrapFunctional(c.default || c))
export const GalleryImageAnnotations = () => import('../../components/Gallery/ImageAnnotations.vue' /* webpackChunkName: "components/gallery-image-annotations" */).then(c => wrapFunctional(c.default || c))
export const GallerySwiper = () => import('../../components/Gallery/Swiper.vue' /* webpackChunkName: "components/gallery-swiper" */).then(c => wrapFunctional(c.default || c))
export const LayoutArchiveBox = () => import('../../components/Layout/ArchiveBox.vue' /* webpackChunkName: "components/layout-archive-box" */).then(c => wrapFunctional(c.default || c))
export const LayoutColumns = () => import('../../components/Layout/Columns.vue' /* webpackChunkName: "components/layout-columns" */).then(c => wrapFunctional(c.default || c))
export const LayoutMedia = () => import('../../components/Layout/Media.vue' /* webpackChunkName: "components/layout-media" */).then(c => wrapFunctional(c.default || c))
export const LayoutMulti = () => import('../../components/Layout/Multi.vue' /* webpackChunkName: "components/layout-multi" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageBuilderTemplate = () => import('../../components/Layout/PageBuilderTemplate.vue' /* webpackChunkName: "components/layout-page-builder-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutProductBox = () => import('../../components/Layout/ProductBox.vue' /* webpackChunkName: "components/layout-product-box" */).then(c => wrapFunctional(c.default || c))
export const LayoutSolo = () => import('../../components/Layout/Solo.vue' /* webpackChunkName: "components/layout-solo" */).then(c => wrapFunctional(c.default || c))
export const LayoutTextLayout = () => import('../../components/Layout/TextLayout.vue' /* webpackChunkName: "components/layout-text-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutTextSliderTemplate = () => import('../../components/Layout/TextSliderTemplate.vue' /* webpackChunkName: "components/layout-text-slider-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutWideBanner = () => import('../../components/Layout/WideBanner.vue' /* webpackChunkName: "components/layout-wide-banner" */).then(c => wrapFunctional(c.default || c))
export const PaymentsPaypal = () => import('../../components/Payments/Paypal.vue' /* webpackChunkName: "components/payments-paypal" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStripe = () => import('../../components/Payments/Stripe.vue' /* webpackChunkName: "components/payments-stripe" */).then(c => wrapFunctional(c.default || c))
export const PaymentsStripeRequest = () => import('../../components/Payments/StripeRequest.vue' /* webpackChunkName: "components/payments-stripe-request" */).then(c => wrapFunctional(c.default || c))
export const ProductExternalPage = () => import('../../components/Product/ExternalPage.vue' /* webpackChunkName: "components/product-external-page" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/Product/Gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupedPage = () => import('../../components/Product/GroupedPage.vue' /* webpackChunkName: "components/product-grouped-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/Product/Page.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/Product/Price.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductSimplePage = () => import('../../components/Product/SimplePage.vue' /* webpackChunkName: "components/product-simple-page" */).then(c => wrapFunctional(c.default || c))
export const ProductVariablePage = () => import('../../components/Product/VariablePage.vue' /* webpackChunkName: "components/product-variable-page" */).then(c => wrapFunctional(c.default || c))
export const ProductsExternal = () => import('../../components/Products/External.vue' /* webpackChunkName: "components/products-external" */).then(c => wrapFunctional(c.default || c))
export const ProductsGrouped = () => import('../../components/Products/Grouped.vue' /* webpackChunkName: "components/products-grouped" */).then(c => wrapFunctional(c.default || c))
export const ProductsProduct = () => import('../../components/Products/Product.vue' /* webpackChunkName: "components/products-product" */).then(c => wrapFunctional(c.default || c))
export const Products = () => import('../../components/Products/Products.vue' /* webpackChunkName: "components/products" */).then(c => wrapFunctional(c.default || c))
export const ProductsSimple = () => import('../../components/Products/Simple.vue' /* webpackChunkName: "components/products-simple" */).then(c => wrapFunctional(c.default || c))
export const ProductsVariable = () => import('../../components/Products/Variable.vue' /* webpackChunkName: "components/products-variable" */).then(c => wrapFunctional(c.default || c))
export const LayoutTextSliderAlignmentHorizontal = () => import('../../components/Layout/TextSliderAlignment/Horizontal.vue' /* webpackChunkName: "components/layout-text-slider-alignment-horizontal" */).then(c => wrapFunctional(c.default || c))
export const LayoutTextSliderAlignmentVertical = () => import('../../components/Layout/TextSliderAlignment/Vertical.vue' /* webpackChunkName: "components/layout-text-slider-alignment-vertical" */).then(c => wrapFunctional(c.default || c))
export const LayoutTextSliderAlignmentMixin = () => import('../../components/Layout/TextSliderAlignment/mixin.js' /* webpackChunkName: "components/layout-text-slider-alignment-mixin" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
