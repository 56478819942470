//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      maxItems: 5,
    }
  },
  computed: {
    ...mapGetters('cart', {
      cartItems: 'items',
    }),
  },
}
